<template>
    <section class="reviews container-fluid h-100 p-3">
        <div class="row h-100 m-0">
            <div class="col-lg-7 col-xl-8 h-100 overflow-auto">
                <div class="position-sticky title">
                    <div class="d-flex flex-nowrap justify-content-between align-items-end">
                        <div class="d-flex flex-nowrap align-items-center">
                            <div class="d-flex d-xl-none align-items-center">
                                <button-toggle-side-bar></button-toggle-side-bar>
                            </div>
                            <h2 class="pl-3 pl-xl-0 mb-0 mr-2">
                            <span class="badge badge-dark">
                                {{ reviewTotal }}
                            </span>
                            </h2>
                            <div class="d-flex align-items-end">
                                <h1 class="font-weight-bolder flex-grow-1 mb-0">
                                    Reviews
                                </h1>
                            </div>
                            <ul class="nav align-self-end">
                                <li class="nav-item">
                                    <router-link class="nav-link py-1"
                                                 v-bind:to="`/organizers`">
                                        Organizers
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="d-flex flex-nowrap">
                            <region-select class="mr-2"></region-select>
                        </div>
                    </div>
                    <div class="py-3 d-flex flex-nowrap justify-content-between">
                        <search-bar class="search-bar flex-grow-1 mr-2"
                                    v-model="keywords">
                        </search-bar>
                        <div class="d-flex align-items-center">
                            <b-form-checkbox class="toggle-with-content"
                                             switch
                                             size="lg"
                                             v-model="isOnlyWithContent">
                                Only with content
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <review-item class="col-12 px-2 mb-2"
                                    v-for="review in reviewsFiltered"
                                    v-bind:key="review._id"
                                    v-bind:review="review">
                    </review-item>
                    <div class="list-end"
                         v-observe-visibility="visibilityChanged">
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-xl-4 mx-3 mx-lg-0 p-lg-0 h-100">
                <router-view></router-view>
            </div>
        </div>
    </section>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import { ObserveVisibility, } from "vue-observe-visibility";
export default {
    name: "Reviews",
    metaInfo () {
        return {
            "title": "Reviews",
        };
    },
    directives: {
        "observe-visibility": ObserveVisibility,
    },
    components: {
        BFormCheckbox,
        SearchBar: () => import("@/components/layout/SearchBar"),
        RegionSelect: () => import("@/components/layout/RegionSelect"),
        ButtonToggleSideBar: () => import("@/components/layout/ButtonToggleSideBar"),
        ReviewItem: () => import("@/components/Reviews/ReviewItem"),
    },
    data () {
        return {
            isOnlyWithContent: false,
            keywords: "",
        };
    },
    computed: {
        currentRegion () {
            return this.$route.params?.region ?? "HKG";
        },
        reviews () {
            return this.$store.getters["evaluation/items"];
        },
        reviewTotal () {
            return this.$store.getters["evaluation/total"];
        },
        reviewsFiltered () {
            return this.isOnlyWithContent ?
                this.reviews.filter(({ answers: { feedback } }) => feedback) :
                this.reviews;
        },
    },
    methods: {
        visibilityChanged (isVisible) {
            if (isVisible) {
                this.$store.dispatch("evaluation/loadMore");
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 2;
}

.list-end {
    height: 2px;
}
</style>

<style lang="scss">
.toggle-with-content {
    .custom-control-label {
        font-size: 1rem !important;
        line-height: 1.1 !important;
        width: 90px;
    }
}
</style>
